import { useAppContext } from "../AppContext";
import { DeleteOutlined } from "@ant-design/icons";

import { Card, Button } from "antd";

export default function Responses() {
  const { listByStatus, fullDateOptions, clearList } = useAppContext();
  return (
    <>
      {listByStatus("correct").length > 0 && (
        <Card
          title={`${listByStatus("correct").length} Correct`}
          extra={
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => clearList("correct")}
            />
          }
        >
          {listByStatus("correct").map((item) => (
            <div key={item.id}>
              <h3>{item.date.toLocaleString("en-us", fullDateOptions)}</h3>
            </div>
          ))}
        </Card>
      )}
      {listByStatus("wrong").length > 0 && (
        <Card
          title={`${listByStatus("wrong").length} Incorrect`}
          extra={
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => clearList("wrong")}
            />
          }
        >
          {listByStatus("wrong").map((item) => (
            <div key={item.id}>
              <h3>{item.date.toLocaleString("en-us", fullDateOptions)}</h3>
            </div>
          ))}
        </Card>
      )}
    </>
  );
}
