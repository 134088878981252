import { useState, createContext, useContext } from "react";

import * as uuid from "uuid";
export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const fullDateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const listByStatus = (status) => {
    return list.filter((item) => item.status === status);
  };

  const randomDate = (start, end) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };

  const addToList = () => {
    setList([
      ...list,
      {
        id: uuid.v4(),
        date: randomDate(new Date(1600, 0, 1), new Date(2300, 11, 30)),
        showDay: false,
        status: "pending",
      },
    ]);
  };

  const setItemStatus = (id, status) => {
    setList(
      list.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status,
          };
        }
        return item;
      })
    );
  };

  const clearList = (status = "all") => {
    if (status === "all") {
      setList([]);
    } else {
      setList(list.filter((item) => item.status !== status));
    }
  };

  const toggleDay = (id) => {
    const newList = list.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          showDay: !item.showDay,
        };
      }
      return item;
    });
    setList(newList);
    setSelected(newList.find((item) => item.id === id));
  };

  return (
    <AppContext.Provider
      value={{
        list,
        listByStatus,
        addToList,
        clearList,
        toggleDay,
        setItemStatus,
        dateOptions,
        fullDateOptions,
        weekday,
        selected,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
