import { Button, Card, Collapse } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useAppContext } from "../AppContext";
import Doomsday from "./Doomsday";

const { Panel } = Collapse;
export default function Quiz() {
  const { listByStatus, dateOptions, addToList, clearList } = useAppContext();
  return (
    <Card
      title={
        <>
          <Button
            type="primary"
            onClick={addToList}
            icon={<PlusCircleOutlined />}
          />{" "}
          Date List
        </>
      }
      extra={
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => clearList("pending")}
        />
      }
    >
      <Collapse>
        {listByStatus("pending").map((item) => (
          <Panel
            header={item.date.toLocaleString("en-us", dateOptions)}
            key={item.id}
          >
            <Doomsday date={item.date} />
          </Panel>
        ))}
      </Collapse>
    </Card>
  );
}
