import { Row, Col } from "antd";
import { useContext } from "react";
import { AppContext } from "../AppContext";

export default function Doomsday({ date }) {
  const { fullDateOptions } = useContext(AppContext);
  const targetDate = new Date(date);
  const doomsdayCenturies = [
    {
      day: "Wed",
      value: 3,
      centuries: [1500, 1900, 2300],
    },
    {
      day: "Tues",
      value: 2,
      centuries: [1600, 2000, 2400],
    },
    {
      day: "Sun",
      value: 0,
      centuries: [1700, 2100, 2500],
    },
    {
      day: "Fri",
      value: 5,
      centuries: [1800, 2200, 2600],
    },
  ];

  const twoDigitYear =
    targetDate && parseInt(targetDate.getFullYear().toString().slice(-2));

  /* const leapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };*/
  // const month = targetDate && targetDate.getMonth();
  const century =
    targetDate &&
    parseInt(targetDate.getFullYear().toString().substring(0, 2) + "00");
  // const isLeap = targetDate && leapYear(targetDate.getFullYear());
  // const leapValue = isLeap && targetDate.getMonth() < 2 ? 1 : 0;

  const doomsdayCenturyValue = doomsdayCenturies.find((x) =>
    x.centuries.find((c) => c === century)
  )?.value;

  const sum =
    parseInt(doomsdayCenturyValue) +
    parseInt(twoDigitYear / 12) +
    parseInt(twoDigitYear % 12) +
    parseInt((twoDigitYear % 12) / 4);

  const log = () => {
    console.log("a", doomsdayCenturyValue);
    console.log("b", twoDigitYear / 12);
    console.log("c", twoDigitYear % 12);
    console.log("d", (twoDigitYear % 12) / 4);
  };

  return (
    <>
      {targetDate && (
        <>
          <h1 onClick={() => log()}>
            {targetDate.toLocaleString("en-us", fullDateOptions)}
          </h1>
          <Row className={"doomsday-a"} gutter={8}>
            <Col span={6} style={{ textAlign: "right" }}>
              <h3>
                <span className={"doomsday-text"}>
                  a ={" "}
                  <span className={"text-century"}>{doomsdayCenturyValue}</span>
                </span>
              </h3>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24}>
                  <h3>
                    Find the <b>doomsday</b> of the{" "}
                    <span className={"text-century"}>century</span>.
                  </h3>
                  <table>
                    <tbody className={"doomsday-century-table"}>
                      <tr>
                        <td
                          className={
                            century === 1500 ? "text-century" : "alpha-half"
                          }
                        >
                          1500
                        </td>
                        <td
                          className={
                            century === 1600 ? "text-century" : "alpha-half"
                          }
                        >
                          1600
                        </td>
                        <td
                          className={
                            century === 1700 ? "text-century" : "alpha-half"
                          }
                        >
                          1700
                        </td>
                        <td
                          className={
                            century === 1800 ? "text-century" : "alpha-half"
                          }
                        >
                          1800
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={
                            century === 1900 ? "text-century" : "alpha-half"
                          }
                        >
                          1900
                        </td>
                        <td
                          className={
                            century === 2000 ? "text-century" : "alpha-half"
                          }
                        >
                          2000
                        </td>
                        <td
                          className={
                            century === 2100 ? "text-century" : "alpha-half"
                          }
                        >
                          2100
                        </td>
                        <td
                          className={
                            century === 2200 ? "text-century" : "alpha-half"
                          }
                        >
                          2200
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={
                            century === 2300 ? "text-century" : "alpha-half"
                          }
                        >
                          2300
                        </td>
                        <td
                          className={
                            century === 2400 ? "text-century" : "alpha-half"
                          }
                        >
                          2400
                        </td>
                        <td
                          className={
                            century === 2500 ? "text-century" : "alpha-half"
                          }
                        >
                          2500
                        </td>
                        <td
                          className={
                            century === 2600 ? "text-century" : "alpha-half"
                          }
                        >
                          2600
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={
                            century === 1500 ||
                            century === 1900 ||
                            century === 2300
                              ? "text-century"
                              : "alpha-half"
                          }
                        >
                          Wed <sup>3</sup>
                        </td>
                        <td
                          className={
                            century === 1600 ||
                            century === 2000 ||
                            century === 2400
                              ? "text-century"
                              : "alpha-half"
                          }
                        >
                          Tues <sup>2</sup>
                        </td>
                        <td
                          className={
                            century === 1700 ||
                            century === 2100 ||
                            century === 2500
                              ? "text-century"
                              : "alpha-half"
                          }
                        >
                          Sun <sup>0</sup>
                        </td>
                        <td
                          className={
                            century === 1800 ||
                            century === 2200 ||
                            century === 2600
                              ? "text-century"
                              : "alpha-half"
                          }
                        >
                          Fri <sup>5</sup>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={"doomsday-b"} gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>{twoDigitYear}</h3>
                </Col>

                <Col span={18}>
                  <h3>
                    Take the <b>last two digits</b> of the year.
                  </h3>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>➗ 12</h3>
                </Col>
                <Col span={18}>
                  <h3>Divide by 12</h3>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>
                    <span className={"doomsday-text"}>
                      b = {parseInt(twoDigitYear / 12)}
                    </span>
                  </h3>
                </Col>
                <Col span={18}>
                  <h3>
                    Any remainder will be used for{" "}
                    <span className={"doomsday-text"}>c</span>
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={"doomsday-c"} gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>
                    <span className={"doomsday-text"}>
                      c = {parseInt(twoDigitYear % 12)}
                    </span>
                  </h3>
                </Col>
                <Col span={18}>
                  <h3>Remainder of {twoDigitYear} ➗ 12</h3>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"doomsday-d"} gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>➗ 4</h3>
                </Col>
                <Col span={18}>
                  <h3>
                    Divide <span className={"doomsday-text"}>c</span> by 4
                  </h3>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>
                    <span className={"doomsday-text"}>
                      d = {parseInt((twoDigitYear % 12) / 4)}
                    </span>
                  </h3>
                </Col>
                <Col span={18}>
                  <h3>Ignore remainder</h3>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"doomsday-e"} gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>
                    {doomsdayCenturyValue} + {parseInt(twoDigitYear / 12)} +{" "}
                    {parseInt(twoDigitYear % 12)} +{" "}
                    {parseInt((twoDigitYear % 12) / 4)}
                  </h3>
                </Col>
                <Col span={18}>
                  <h3>
                    <span className={"doomsday-text"}>a + b + c + d </span>
                  </h3>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={6} className={"cell-sum"}>
                  <h3>{sum}</h3>
                </Col>
                <Col span={18}>
                  <h3>
                    Subtract 7 until &lt; 6{" "}
                    <span className={"alpha-half"}>
                      (or get the remainder when divided by 7)
                    </span>
                  </h3>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={6} style={{ textAlign: "right" }}>
                  <h3>
                    <span className={"doomsday-text"}>e = {sum % 7}</span>
                  </h3>
                </Col>
                <Col span={18}>
                  <h3>Count Forward this many days from doomsday</h3>
                  <Row>
                    <Col span={12}>
                      <h2>Doomsdays</h2>
                      <ul>
                        <li>1/3 (1/4 on leap years)</li>
                        <li>2/28 (2/29 on leap years)</li>
                        <li>3/14</li>
                        <li>4/4</li>
                        <li>5/9</li>
                        <li>6/6</li>
                        <li>7/11</li>
                        <li>8/8</li>
                        <li>9/5</li>
                        <li>10/10</li>
                        <li>11/11</li>
                        <li>12/12</li>
                      </ul>
                    </Col>
                    <Col span={12}>
                      <h2>Day of week index</h2>
                      <ul>
                        <li>0 - Noneday - Sunday</li>
                        <li>1 - Oneday - Monday</li>
                        <li>2 - Twosday - Tuesday</li>
                        <li>3 - Threesday - Wednesday</li>
                        <li>4 - Foursday - Thursday</li>
                        <li>5 - Fiveday - Friday</li>
                        <li>6 - Sixturday - Saturday</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={6} style={{ textAlign: "right" }}></Col>
          </Row>
        </>
      )}
    </>
  );
}
