import { Col, Row } from "antd";
import "antd/dist/reset.css";
import "./App.css";
import Quiz from "./components/Quiz";
import { AppProvider } from "./AppContext";
import Responses from "./components/Responses";

function App() {
  return (
    <AppProvider>
      <Row>
        <Col span={24}>
          <Quiz />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Responses />
        </Col>
      </Row>
    </AppProvider>
  );
}

export default App;
